@import "../../../../assets/theme/_var.scss";

.mission_vision {
    padding: 10rem 0;

    @media (max-width: 1599px) {
        padding: 8rem 0;
    }

    @media (max-width: 1439px) {
        padding: 6rem 0;
    }

    @media (max-width: 767px) {
        padding: 4rem 0;
    }

    .mission_box {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 6rem;
        color: $white;
        text-align: center;
        padding: 10rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 1599px) {
            padding: 8rem 0;
        }

        @media (max-width: 1439px) {
            padding: 6rem 0;
            margin-bottom: 4rem;
        }

        @media (max-width: 767px) {
            margin-bottom: 2rem;
            padding: 4rem 0;
        }

        .mission_box_inner {
            max-width: 82.4rem;
            margin-inline: auto;
            padding-inline: 1.2rem;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &.mission {
            background-image: linear-gradient(180deg, rgba($black, 0.6), rgba($black, 0.6)), url(../../../../assets/images/aboutus/mission.png);
        }

        &.vision {
            background-image: linear-gradient(180deg, rgba($black, 0.6), rgba($black, 0.6)), url(../../../../assets/images/aboutus/vision.png);
        }

        p {
            margin-top: 5rem;

            @media (max-width: 1679px) {
                margin-top: 4rem;
            }

            @media (max-width: 1439px) {
                margin-top: 3rem;
            }

            @media (max-width: 1279px) {
                margin-top: 2rem;
            }

            @media (max-width: 991px) {
                margin-top: 1rem;
            }
        }
    }
}