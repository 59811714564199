@import "../../../../assets/theme/_var.scss";

.custom_area {
    label {
        font-size: 1.4rem;
        font-weight: 500;
        color: $baseclr;
        margin-bottom: 0.4rem;
    }

    textarea {
        border: 1px solid $borderclr;
        outline: none;
        height: 6rem;
        padding: 1rem;
        font-size: 1.6rem;
        line-height: 1;
        width: 100%;

        &:focus {
            border: 1px solid $borderclr2;
        }
    }
}