@import "../../../assets/theme/_var.scss";

.service_sec {
  // background-image: url(../../../assets/images/serv.jpg);
  // background-size: cover;
  // background-position: center;
  color: $white;
  background-image: linear-gradient(180deg,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)),
    url(../../../assets/images/serv.jpg);
  background-position: bottom;
  background-size: cover;
  padding: 10rem 0;

  @media (max-width: 1679px) {
    padding: 8rem 0;
  }

  @media (max-width: 1439px) {
    padding: 6rem 0;
  }

  @media (max-width: 1199px) {
    padding: 4rem 0;
  }

  @media (max-width: 991px) {
    padding: 0rem 0;
  }

  .heading {
    h2 {
      color: #936012;
      font-family: Poppins, sans-serif;
      font-size: 4.8rem;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 1.5;
      margin: 5.7rem 0 3rem;
      // max-width: 80%;
      max-width: 100%;
      text-transform: capitalize;
      width: 100%;

      @media (max-width: 1679px) {
        font-size: 4rem;
      }

      @media (max-width: 1439px) {
        font-size: 3.5rem;
      }

      @media (max-width: 1199px) {
        font-size: 3rem;
        max-width: 100%;
      }
    }
  }

  &_inner {
    max-width: 70%;
    width: 100%;

    // padding: 6rem 0;
    h3 {
      margin-bottom: 3rem;
    }
  }

  &_search {
    margin-top: 3rem;

    h4 {
      background-color: rgba(0, 0, 0, 0.478);
      font-size: 2.4rem;
      margin-bottom: 2rem;
      padding: 1rem;
      text-transform: uppercase;

      @media (max-width: 1439px) {
        font-size: 2.2rem;
      }

      @media (max-width: 1199px) {
        font-size: 2rem;
      }

      @media (max-width: 991px) {
        font-size: 1.8rem;
      }
    }

    &_inner {
      display: flex;
      padding-bottom: 6rem;
      justify-content: space-between;

      @media (max-width: 767px) {
        flex-direction: column;
      }

      form {
        flex: 0 0 100%;

        .row {
          >div {
            padding: 0;
          }
        }
      }

      .start_btn {
        height: 6rem;
        border-radius: 0;
      }
    }
  }
}

.unlock_sec {
  margin-top: 14rem;

  @media (max-width: 1679px) {
    margin-top: 10rem;
  }

  @media (max-width: 1439px) {
    margin-top: 8rem;
  }

  @media (max-width: 1365px) {
    margin-top: 6rem;
  }

  .unlock_sec_inner {
    position: relative;
    z-index: 0;
    display: flex;

    .unlock_lines {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    img {
      margin: 4rem 0 0 auto;
      width: 44rem;
      height: 44rem;
      border-radius: 50%;
      object-fit: cover;
      object-position: left;

      @media (max-width: 1439px) {
        width: 40rem;
        height: 40rem;
      }

      @media (max-width: 1199px) {
        width: 35rem;
        height: 35rem;
      }

      @media (max-width: 991px) {
        width: 30rem;
        height: 30rem;
        margin: 4rem auto 0;
      }
    }

    button {
      position: absolute;
      bottom: 0;
      right: 0;

      @media (max-width: 991px) {
        right: 50%;
        transform: translateX(50%);
      }
    }
  }

  .unlock_sec_info {
    h4 {
      font-size: 2.2rem;
      font-weight: 400;
      color: rgba($baseclr, 0.5);
    }

    h2 {
      font-size: 3.4rem;
      line-height: 1.6;
      margin: 2rem 0;
      font-weight: 700;

      @media (max-width: 1679px) {
        font-size: 3.2rem;
      }

      @media (max-width: 1439px) {
        font-size: 3rem;
      }

      @media (max-width: 1199px) {
        margin: 2rem 0;
        line-height: 1.6;
        font-size: 2.5rem;
      }
    }

    p {
      font-size: 2rem;
      font-weight: 400;
      line-height: 186.5%;
      letter-spacing: 0.9px;

      @media (max-width: 1679px) {
        font-size: 1.8rem;
      }

      @media (max-width: 1439px) {
        font-size: 1.6rem;
      }

      @media (max-width: 991px) {
        font-size: 1.4rem;
      }
    }
  }
}