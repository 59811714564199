@import "../../../assets/theme/_var.scss";

.section_heading {
    h2 {
        font-size: 4.4rem;
        font-weight: 700;
        line-height: 5.4rem;
        text-transform: capitalize;
        font-family: $headingfont;

        @media (max-width: 1679px) {
            font-size: 3.8rem;
            line-height: 1.5;
        }

        @media (max-width: 1399px) {
            font-size: 3rem;
        }

        @media (max-width: 1279px) {
            font-size: 2.8rem;
        }

        @media (max-width: 1199px) {
            font-size: 2.6rem;
        }

        @media (max-width: 991px) {
            font-size: 2.2rem;
        }
    }

    .sub_text {
        margin-top: 4rem;

        @media (max-width: 1439px) {
            margin-top: 3rem;
        }

        @media (max-width: 1279px) {
            margin-top: 2rem;
        }

        p {
            font-size: 2.2rem;
            font-weight: 500;
            line-height: 1.6;
            margin-bottom: 4rem;

            @media (max-width: 1679px) {
                font-size: 2rem;
                margin-bottom: 3rem;
            }

            @media (max-width: 1399px) {
                font-size: 1.8rem;
                margin-bottom: 2rem;
            }

            @media (max-width: 1279px) {
                font-size: 1.6rem;
            }

            @media (max-width: 991px) {
                font-size: 1.4rem;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}