@import "../../../assets/theme/_var.scss";

.community_sec_about {
  //   background-image: url(../../../assets/images/COP.png);
  //   background-size: cover;
  //   background-position: center;
  color: $greyclr;
  padding: 9rem 0 25rem;
  text-align: center;

  @media (max-width: 1679px) {
    padding: 9rem 0;
  }

  @media (max-width: 1439px) {
    padding: 9rem 0 15rem;
  }

  @media (max-width: 1199px) {
    padding: 7rem 0 10rem;
  }

  @media (max-width: 575px) {
    padding: 5rem 0 5rem;
  }

  h2 {
    margin-bottom: 9rem;
    font-family: $headingfont;
    font-size: 4.4rem;

    @media (max-width: 991px) {
      font-size: 3.2rem;
      margin-bottom: 6rem;
    }

    @media (max-width: 575px) {
      font-size: 2.6rem;
      margin-bottom: 4rem;
    }
  }

  p {
    font-size: 2.2rem;
    font-weight: 400;
    left: 0.56;
    line-height: normal;

    @media (max-width: 991px) {
      font-size: 2rem;
    }

    @media (max-width: 575px) {
      font-size: 1.6rem;
    }
  }

  a {
    &.email_link {
      font-size: 2.2rem;
      font-weight: 400;
      left: 0.56;
      line-height: normal;
      color: $black;
      text-decoration: underline;
      margin-top: 1rem;

      @media (max-width: 991px) {
        font-size: 2rem;
      }

      @media (max-width: 575px) {
        font-size: 1.6rem;
      }
    }
  }
}