@import '../../../../assets/theme/_var.scss';


.values_sec {
    padding: 10rem 0;
    background-color: $sectionbg;
    position: relative;
    z-index: 0;

    @media (max-width: 1599px) {
        padding: 8rem 0;
    }

    @media (max-width: 1439px) {
        padding: 6rem 0;
    }

    @media (max-width: 767px) {
        padding: 4rem 0;
    }

    .right_dots {
        position: absolute;
        right: 0;
        z-index: -1;
        top: 0;

        @media (max-width: 1439px) {
            max-width: 24rem;
        }

        @media (max-width: 991px) {
            top: 10rem;
        }

        svg {

            ellipse,
            circle {
                fill: $secondaryclr;
            }
        }
    }

    .bottom_dots {
        position: absolute;
        bottom: 1.6rem;
        z-index: -1;
        left: 8rem;
        width: max-content;

        @media (max-width: 1439px) {
            max-width: 24rem;
        }

        svg {

            ellipse,
            circle {
                fill: $secondaryclr;
            }
        }
    }

    .cursive_lines {
        position: absolute;
        bottom: 1.6rem;
        z-index: -1;
        right: 20rem;
        width: max-content;

        @media (max-width: 1439px) {
            right: 0;
            max-width: 20rem;
        }

        svg {

            path {
                stroke: $secondaryclr;
            }
        }
    }

    .section_heading {
        text-align: center;
        margin-bottom: 10rem;

        @media (max-width: 1439px) {
            margin-bottom: 8rem;
        }

        @media (max-width: 1399px) {
            margin-bottom: 6rem;
        }

        @media (max-width: 991px) {
            margin-bottom: 4rem;
        }
    }

    .container {
        @media (max-width: 1599px) {
            max-width: 124rem;
        }

        @media (max-width: 1439px) {
            max-width: 88rem;
        }
    }

    .row {
        margin: -1.6rem;
        justify-content: center;

        >div {
            padding: 1.6rem;

            .value_box {
                box-shadow: 4px 4px 1.6rem -4px rgba($black, 0.1);
                background-color: $white;
                height: 100%;
                padding: 7rem 5rem;
                position: relative;

                @media (max-width: 1679px) {
                    padding: 6rem 3rem;
                }

                @media (max-width: 1439px) {
                    padding: 4rem 2rem;
                }

                @media (max-width: 991px) {
                    padding: 3rem;
                    border-radius: 0.8rem;
                }

                @media (max-width: 767px) {
                    padding: 2rem;
                }

                @media (max-width: 575px) {
                    text-align: center;
                }

                .value_icon {
                    position: absolute;
                    top: 0;
                    z-index: 1;

                    @media (max-width: 1679px) {
                        max-width: 15rem;
                    }

                    @media (max-width: 1399px) {
                        max-width: 10rem;

                        &[alt="nobility-icon"] {
                            max-width: 15rem;
                        }
                    }

                    @media (max-width: 991px) {
                        position: static;
                        transform: none !important;
                        margin-bottom: 2rem;
                        max-width: 8rem;

                        &[alt="nobility-icon"] {
                            max-width: 12rem;
                        }
                    }
                }

                h3 {
                    color: $baseclr;
                    font-size: 3rem;
                    margin-bottom: 2.8rem;

                    span {
                        font-family: $headingfont;
                        font-size: 7rem;
                        text-transform: uppercase;
                    }

                    @media (max-width: 1679px) {
                        font-size: 2.8rem;
                        margin-bottom: 2.4rem;

                        span {
                            font-size: 6rem;
                        }
                    }

                    @media (max-width: 1439px) {
                        font-size: 2.6rem;
                        margin-bottom: 2rem;

                        span {
                            font-size: 5.4rem;
                        }
                    }

                    @media (max-width: 1399px) {
                        font-size: 2.2rem;
                        margin-bottom: 1.6rem;

                        span {
                            font-size: 4.4rem;
                        }
                    }

                    @media (max-width: 1199px) {
                        font-size: 2rem;

                        span {
                            font-size: 3.4rem;
                        }
                    }

                    @media (max-width: 991px) {
                        font-size: 1.8rem;
                    }

                    @media (max-width: 767px) {
                        font-size: 1.6rem;
                    }
                }

                p {
                    font-size: 2.4rem;
                    line-height: 1.7;
                    letter-spacing: 0.6px;

                    @media (max-width: 1679px) {
                        font-size: 2.2rem
                    }

                    @media (max-width: 1399px) {
                        font-size: 2rem;
                    }

                    @media (max-width: 1279px) {
                        font-size: 1.8rem;
                    }

                    @media (max-width: 1199px) {
                        font-size: 1.6rem;
                    }

                    @media (max-width: 767px) {
                        font-size: 1.4rem;
                    }

                }
            }

            &:nth-child(even) {
                .value_box {
                    .value_icon {
                        right: 0;
                        transform: translate(50%, -50%);
                    }
                }
            }

            &:nth-child(odd) {
                .value_box {
                    .value_icon {
                        left: 0;
                        transform: translate(-50%, -50%);
                    }
                }
            }

            &:last-child {
                .value_box {
                    text-align: center;

                    .value_icon {
                        left: 50%;
                        margin-bottom: 0;
                    }

                    h3 {
                        margin-top: 4rem;

                        @media (max-width: 1199px) {
                            margin-top: 3rem;
                        }

                        @media (max-width: 991px) {
                            margin-top: 2rem;
                        }
                    }
                }
            }
        }
    }

}