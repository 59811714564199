@import "../../../../assets/theme/_var.scss";

.about_banner {
    padding: 8.5rem 0;
    background-color: $sectionbg;

    @media (max-width: 1679px) {
        padding: 7rem 0;
    }

    @media (max-width:1439px) {
        padding: 6rem 0;
    }

    @media (max-width: 1399px) {
        padding: 5rem 0;
    }
}