@import "../../../../assets/theme/_var.scss";

.header_nav {
    margin-inline: auto;

    ul {
        display: flex;
        height: 100%;
        margin-inline: -1rem;

        li {
            padding-inline: 1rem;

            .dropdown {
                height: 100%;

                .dropdown-toggle {
                    height: 100%;
                    background-color: transparent;
                    box-shadow: none;
                    border: none;
                    outline: none;
                    padding: 0;

                    &::after {
                        content: unset;
                    }

                    &:active {
                        box-shadow: none;
                    }
                }

                .dropdown-menu {
                    min-width: auto;
                    padding: 0;
                    border-radius: 0.8rem;
                    border: 1px solid $borderclr;
                    width: max-content;
                    max-width: 24rem;

                    >* {
                        &:first-child {
                            border-radius: 1rem 1rem 0 0;
                        }

                        &:last-child {
                            border-radius: 0 0 1rem 1rem;
                        }
                    }

                    a {
                        padding: 0.8rem 1rem;

                        &::before {
                            width: 100%;
                        }

                        &:hover {
                            background-color: $headerDropdownHoverBg;
                        }
                    }
                }
            }

            a,
            .dropdown .dropdown-toggle {
                height: 100%;
                position: relative;
                display: flex;
                color: $baseclr;
                font-weight: 500;
                align-items: center;
                font-size: 1.8rem;
                padding-bottom: 0.8rem;

                @media (max-width: 1439px) {
                    font-size: 1.6rem;
                }

                @media (max-width: 991px) {
                    font-size: 1.4rem;
                }

                &::before {
                    width: calc(100% + 1.6rem);
                    height: 0.4rem;
                    background-color: $secondaryclr;
                    border-radius: 0.8rem 0.8rem 0 0;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }

                &.active,
                &.show,
                &:hover {
                    color: $secondaryclr;

                    &::before {
                        content: "";
                    }
                }
            }
        }
    }

    @media (max-width: 575px) {
        position: fixed;
        top: 0;
        width: 100%;
        max-width: 26rem;
        height: 100vh;
        background-color: $white;
        z-index: $sidebar;
        padding: 2rem 1.5rem;
        left: 0;
        transform: translate(-100%, 0);
        transition: 200ms ease-in-out;

        .nav_logo {
            max-width: 14rem;
            margin: 0 auto 4rem;
            display: block;
        }

        ul {
            flex-wrap: wrap;
            height: auto;

            li {
                width: 100%;

                a,
                .dropdown .dropdown-toggle {
                    display: block;
                    width: 100%;
                    text-align: left;
                    padding: 1rem 0;
                    height: auto;

                    &::before {
                        max-width: 100%;
                    }
                }

                .dropdown {
                    height: auto;

                    .dropdown-menu {
                        transform: none !important;
                        position: static !important;
                        border: none;
                        border-radius: 1rem;

                        a {
                            padding-left: 2rem;

                            &::after {
                                content: "";
                                position: absolute;
                                left: 0;
                                top: 1.2rem;
                                background-image: url(../../../../assets/images/svgs/right-arrow.svg);
                                width: 1.6rem;
                                height: 1.6rem;
                                background-size: 1.2rem;
                                background-position: center;
                                background-repeat: no-repeat;
                            }
                        }
                    }
                }
            }
        }

        &.active {
            transform: translate(0, 0);
        }

    }
}

.sidebar_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: $sidebarOverlay;
    backdrop-filter: blur(0.4rem);
    background-color: rgba($white, 0.0);
    opacity: 0;
    visibility: hidden;
    transition: 200ms ease-in-out;

    &.show {
        opacity: 1;
        visibility: visible;
    }
}