@import "../../../../assets/theme/_var.scss";

.team_sec {
    padding: 10rem 0;
    background-color: $baseclr;

    @media (max-width: 1199px) {
        padding: 8rem 0;
    }

    @media (max-width: 767px) {
        padding: 6rem 0;
    }

    .team_dots {
        position: absolute;
        top: -10rem;
        right: 0;

        svg {
            @media (max-width: 1599px) {
                max-width: 26rem;
                height: auto;
            }

            @media (max-width: 1439px) {
                max-width: 20rem;
            }

            @media (max-width: 767px) {
                display: none;
            }

            ellipse,
            circle {
                fill: $white;
                opacity: 0.6;
            }
        }
    }

    .section_heading {
        color: $white;
        margin-bottom: 14.3rem;

        @media (max-width: 1599px) {
            margin-bottom: 10rem;
        }

        @media (max-width: 1199px) {
            margin-bottom: 6rem;
        }

    }

    .team_box {
        &:not(:last-child) {
            margin-bottom: 10.6rem;

            @media (max-width: 1599px) {
                margin-bottom: 8rem;
            }

            @media (max-width: 1439px) {
                margin-bottom: 6rem;
            }

            @media (max-width: 991px) {
                margin-bottom: 4rem;
            }
        }

        .team_header {
            display: flex;
            margin-bottom: 4rem;
            align-items: center;
            color: $white;

            @media (max-width: 1599px) {
                margin-bottom: 3rem;
            }

            @media (max-width: 1439px) {
                margin-bottom: 2.4rem;

            }

            @media (max-width: 991px) {
                font-size: 2.2rem;
                margin-bottom: 1rem;
            }

            img {
                width: 10rem;
                height: 10rem;
                border-radius: 50%;
                margin-right: 4rem;
                flex-shrink: 0;
                object-fit: cover;
                object-position: top;
                background-color: $white;

                @media (max-width: 1439px) {
                    margin-right: 3rem;
                    width: 8rem;
                    height: 8rem;
                }

                @media (max-width: 1199px) {
                    margin-right: 2rem;
                    width: 7rem;
                    height: 7rem;
                }
            }

            h3 {
                font-size: 3.4rem;
                font-weight: 600;
                font-family: $headingfont;
                margin-bottom: 2.2rem;

                @media (max-width: 1599px) {
                    font-size: 3rem;
                    margin-bottom: 2rem;
                }

                @media (max-width: 1439px) {
                    font-size: 2.6rem;
                    margin-bottom: 1.6rem;
                }

                @media (max-width: 991px) {
                    font-size: 2.2rem;
                    margin-bottom: 1rem;
                }

                @media (max-width: 767px) {
                    font-size: 2rem;
                }

                @media (max-width: 575px) {
                    font-size: 1.8rem;
                }

                @media (max-width: 389px) {
                    font-size: 1.6rem;
                }
            }

            p {
                font-size: 2rem;
                line-height: 2rem;

                @media (max-width: 1199px) {
                    font-size: 1.8rem;
                }

                @media (max-width: 991px) {
                    font-size: 1.6rem;
                }

                @media (max-width: 767px) {
                    font-size: 1.4rem;
                }
            }
        }

        .team_about {
            p {
                color: $white;
                font-weight: 400;
                font-size: 2.4rem;
                letter-spacing: 1.5px;
                margin-bottom: 5rem;

                @media (max-width: 1679px) {
                    font-size: 2.2rem;
                    margin-bottom: 4rem;
                }

                @media (max-width: 1599px) {
                    font-size: 2rem;
                    margin-bottom: 3rem;
                }

                @media (max-width: 1439px) {
                    font-size: 1.8rem;
                    margin-bottom: 2rem;
                }

                @media (max-width: 991px) {
                    font-size: 1.6rem;
                }

                @media (max-width: 767px) {
                    margin-bottom: 1.6rem;
                    font-size: 1.4rem;
                }

                &:last-child {
                    margin-bottom: 0;
                }

            }
        }
    }

}