@import "../../../../assets/theme/_var.scss";

.contact_form {
    .row {
        margin: -0.8rem;

        >div {
            padding: 0.8rem;
        }
    }
}