@import "../../../../assets/theme/_var.scss";

.toggler {
    background-color: transparent;
    padding: 0;
    border: none;
    outline: none;
    margin-left: 1rem;

    span {
        display: block;
        width: 2.8rem;
        height: 0.3rem;
        border-radius: 4rem;
        background-color: $baseclr;
        margin-bottom: 0.65rem;
        transform-origin: left;
        transition: 200ms ease-in-out;
    }

    &.active {
        span {
            &:first-child {
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:last-child {
                transform: rotate(-45deg);
            }
        }
    }
}