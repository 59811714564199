@import "../../../assets/theme/_var.scss";

.contact_sec {
  padding: 10rem 0;

  @media (max-width: 1366px) {
    padding: 4rem 0;
  }

  .headings {
    margin-bottom: 2rem;
  }

  // @media (max-width: 767px) {}

  .contact_left {
    background: $contactLeftBg;
    border-radius: 0.8rem;
    padding: 2rem 0;
    max-width: 40rem;
    margin-inline: auto;

    @media (max-width: 767px) {
      margin-bottom: 4rem;
    }

    .contact_animate {
      position: relative;
      z-index: 0;

      .clouds_animate {
        position: absolute;
        top: 8rem;
        width: 100%;
        z-index: -1;
      }
    }

    .contact_social {
      padding-inline: 4rem;
      margin-top: 3rem;

      h3 {
        margin-bottom: 2rem;
        font-size: 1.8rem;
        line-height: 2.8rem;
        font-weight: 600;
        font-family: $headingfont;
      }

      ul {
        display: flex;
        align-items: center;
        margin: -1.6rem;
        flex-wrap: wrap;

        li {
          padding: 1.6rem;

          a {
            width: 3.8rem;
            height: 3.8rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.8rem;
            background-color: $baseclr;
            position: relative;

            span {
              position: absolute;
              top: 0;
              right: 0;
              transform: translate(70%, -70%);
              color: $baseclr;
              font-weight: 700;
            }

            svg {
              transition: 200ms ease-in-out;
              width: 2.4rem;
              height: 2.4rem;

              path {
                fill: $white;
              }
            }

            &:hover {
              background-color: $baseclr2;

              svg {
                transform: translateY(-0.4rem);
              }
            }
          }
        }
      }
    }
  }
}
