@import "../../../assets/theme/_var.scss";

.secondary_btn {
    height: 7.8rem;
    padding: 1.5rem 1.4rem;
    display: flex;
    align-items: center;
    min-width: 11.6rem;
    color: $secondaryclr;
    background-color: transparent;
    border: 1px solid $secondaryclr;
    border-radius: 0.8rem;
    font-size: 1.6rem;
    transition: 200ms ease-in-out;
    font-weight: 500;

    .btn_text {
        align-self: flex-start;
    }

    .btn_icon {
        margin-left: 4rem;
        align-self: flex-end;
        transition: 200ms ease-in-out;
    }

    .btn_loading {
        animation: spinning 3.2s ease-in-out infinite;
        margin-inline: auto;

        svg {
            width: 3rem;
            height: 3rem;

            path {
                fill: $secondaryclr;
            }
        }
    }

    &:hover {
        background-color: $secondaryclr;
        color: $white;

        .btn_icon {
            transform: rotate(-45deg);
        }

        .btn_loading {
            svg {
                path {
                    fill: $white;
                }
            }
        }
    }

    &:active {
        background-color: $secondaryclr2;
    }

    &:disabled {
        opacity: 0.6;
        pointer-events: none;
    }
}



@keyframes spinning {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}