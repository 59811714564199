@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Poppins:wght@300;400;500;600;700;800&display=swap');

// ***** fonts ******
$basefont: 'Poppins', sans-serif;
$headingfont: 'Merriweather', serif;


// ***** colors **** 
$baseclr: #52525f;
$baseclr2: #505455;
$secondaryclr: #936012;
$secondaryclr2: #7c5214;
$white: white;
$greyclr: #363635;
$black: black;
$borderclr: rgba(#1b4545, 0.1);
$borderclr2: #1B4545;
$errorclr: red;

$sectionbg: #EEF4FA;


// **** header ****
$headerDropdownHoverBg: #f5f4f4;



// **** contact us section *** 
$contactLeftBg: linear-gradient(-230deg, #f5f4f4, #fff00000);


// *** z indexes **** 
$header: 955;
$socialHeader: 955;
$socialSidebar: 955;
$sidebar: 957;
$sidebarOverlay: 956;