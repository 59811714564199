@import "../../../../assets/theme/_var.scss";

.services_section {
  padding: 8rem 0;
  @media (max-width: 1366px) {
    padding: 4rem 0;
  }

  .row {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  img {
    max-height: 40rem;
    object-fit: cover;
    width: 100%;
  }

  h3 {
    font-size: 3.2rem;
    font-family: $headingfont;
    margin-bottom: 3rem;

    @media (max-width: 1439px) {
      font-size: 2.8rem;
    }

    @media (max-width: 1199px) {
      font-size: 2.4rem;
      margin-bottom: 2rem;
    }

    @media (max-width: 991px) {
      font-size: 2.2rem;
    }

    @media (max-width: 767px) {
      font-size: 2rem;
      margin-top: 2rem;
    }
  }

  h4 {
    font-size: 2.4rem;
    font-weight: 500;
    margin: 0.4rem 0 1rem;
  }

  ul {
    margin-bottom: 4rem;

    li {
      font-size: 2rem;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        margin-right: 0.8rem;
        width: 3rem;
        height: 3rem;
        background-color: $baseclr;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-size: 1.8rem;
      }
    }
  }

  p {
    font-size: 1.8rem;
    margin-bottom: 1rem;

    @media (max-width: 991px) {
      font-size: 1.6rem;
    }

    @media (max-width: 767px) {
      font-size: 1.4rem;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  button {
    margin-top: 2rem;
  }
}
