@import "../../../../assets/theme/_var.scss";

.custom_input {
    .input_inner {
        position: relative;

        label {
            position: absolute;
            left: 4rem;
            top: 50%;
            transform: translateY(-50%);
            transition: 200ms ease-in-out;
            font-size: 1.4rem;
            font-weight: 500;
            color: $baseclr;
            background-color: $white;
            padding-inline: 0.8rem;
            pointer-events: none;
            opacity: 0.5;
        }

        .input_icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0.8rem;

            svg {
                width: 2rem;
                height: 2rem;

                path {
                    fill: $baseclr;
                }
            }
        }

        input {
            border: 1px solid $borderclr;
            outline: none;
            height: 6rem;
            padding: 0 1rem 0 4rem;
            font-size: 1.6rem;
            line-height: 1;
            width: 100%;

            &:focus,
            &:not([value=""]) {
                border: 1px solid $borderclr2;

                +label {
                    opacity: 1;
                    transform: translateY(-4rem) translateX(-1rem);
                }
            }
        }


    }
}