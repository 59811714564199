@import "../../../assets/theme/_var.scss";

.footer {
    margin-top: auto;
    padding-top: 8rem;

    .container {
        max-width: 152rem;
        padding: 0 1.5rem;

        @media (max-width: 1399px) {
            max-width: 142rem;
        }

        @media (max-width: 1199px) {
            max-width: 110rem;
        }
    }

    .footer_upper {
        display: flex;
        align-items: flex-start;
        background-color: $baseclr;
        padding: 2rem;
        border-radius: 1rem;
        justify-content: space-between;

        @media (max-width: 679px) {
            flex-wrap: wrap;
        }

        .footer_logo {
            @media (max-width: 679px) {
                flex: 0 0 100%;
                text-align: center;
                margin-bottom: 3rem;

                p {
                    margin-inline: auto;
                }
            }

            a {
                max-width: 12rem;
                margin-bottom: 2rem;
            }

            p {
                color: $white;
                font-size: 1.4rem;
                max-width: 30rem;
            }
        }

        ul {
            display: flex;
            align-items: flex-start;
            margin-inline: -2rem;
            padding-left: 3rem;

            @media (max-width: 991px) {
                flex-wrap: wrap;
                max-width: 47rem;
                margin: -1rem -2rem;

                li {
                    flex: 0 0 50%;
                    padding: 1rem 2rem;

                    &:first-child {
                        max-width: 20rem;
                    }

                    &:last-child {
                        flex: 0 0 100%;
                    }
                }
            }

            @media (max-width: 679px) {
                justify-content: center;
                max-width: 100%;
                flex: 0 0 100%;
                margin: 0;
                padding: 0;

                li {
                    flex: 0 0 auto;
                    width: max-content;

                    &:first-child {
                        max-width: 20rem;
                    }

                    &:last-child {
                        flex: 0 0 auto;
                    }
                }
            }

            @media (max-width: 575px) {
                text-align: center;

                li {
                    flex: 0 0 50%;
                }
            }

            @media (max-width: 489px) {
                li {
                    flex: 0 0 100%;
                }
            }

            li {
                padding-inline: 2rem;

                h3 {
                    color: $white;
                    font-size: 1.8rem;
                    line-height: 2rem;
                    margin-bottom: 1rem;

                    @media (max-width: 1399px) {
                        font-size: 1.6rem;
                    }

                    @media (max-width: 767px) {
                        font-size: 1.4rem;
                    }

                    svg {
                        width: 2rem;
                        height: 2rem;

                        path {
                            fill: $white;
                        }
                    }
                }

                a {
                    color: $white;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .social_link {
                    width: 3.4rem;
                    height: 3.4rem;
                    border-radius: 0.8rem;
                    margin-right: 0.8rem;

                    &:last-child {
                        margin: 0;
                    }

                    background-color: $baseclr2;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        width: 2rem;
                        height: 2rem;
                        transition: 200ms ease-in-out;

                        path {
                            fill: $white;
                        }
                    }

                    &:hover {
                        svg {
                            transform: translateY(-0.4rem);
                        }
                    }
                }
            }
        }
    }

    .footer_down {
        background-color: $baseclr;
        border-radius: 1rem 1rem 0 0;
        text-align: center;
        padding: 2rem 1.5rem;
        margin-top: 3rem;

        p {
            color: $white;
            font-weight: 500;

            a {
                color: $white;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}