@import "../../../assets/theme/_var.scss";

.header {
    background-color: $white;
    position: sticky;
    top: 0;
    z-index: $header;

    .header_inner {
        display: flex;
        padding: 1.2rem 0 0;

        .header_logo {
            width: 12rem;
            margin-bottom: 1rem;

            @media (max-width: 991px) {
                width: 10rem;
            }
        }

        .action_btn {
            margin-left: 2rem;

            @media (max-width: 575px) {
                margin-left: auto;
            }
        }
    }

    &.scrolled {
        box-shadow: 0.4rem 0 0.4rem 0.5rem rgba($black , 0.1);
    }
}