@import "./assets/theme/_var.scss";

html {
  font-size: 56.5%;
}

body {
  font-family: $basefont;
}

// *** Scrollbar
::-webkit-scrollbar {
  // width: 0.8rem;
  // height: 0.8rem;
  width: 0;
  height: 0;
  background-color: rgba($baseclr, 0.3);
}

::-webkit-scrollbar-thumb {
  // width: 0.8rem;
  // height: 0.8rem;
  width: 0;
  height: 0;
  background-color: $baseclr;
  border-radius: 1rem;
}

img,
svg,
video,
canvas {
  max-width: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}


a,
span {
  display: inline-block;
}

a,
p {
  font-size: 1.6rem;
  line-height: 1.6;

  @media (max-width: 767px) {
    font-size: 1.4rem;
  }
}

// **** Input Default Reset **** ////
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: $baseclr;
  caret-color: $baseclr;
  -webkit-font-size: 1.6rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.container {
  max-width: 137rem;
  padding: 0 1.5rem;

  @media (max-width: 1479px) {
    max-width: 115rem;
  }

  @media (max-width: 1199px) {
    max-width: 99rem;
  }
}