@import "../../../assets/theme/_var.scss";

.banner_sec {
  &_inner {
    position: relative;

    &_content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      z-index: 1;
      color: $white;
      max-width: 100rem;
      width: 100%;

      @media (max-width: 1279px) {
        max-width: 70rem;
        padding: 0 2rem;
      }


      // position: absolute;
      // top: 0;
      // left: 50%;
      // transform: translateX(-50%);
      // color: $white;
      // z-index: 1;
      // max-width: 100%;
      // width: 100%;
      // // margin-left: 30rem;
      // margin: 14.7rem auto 0;
      // // margin-top: 14.7rem;

      // @media (max-width: 1679px) {
      //   margin-left: 5rem; 
      //   margin-top: 5rem;
      // }

      // @media (max-width: 1366px) {
      //   max-width: 100%;
      //   // margin-left: 10rem;
      // }

      // @media (max-width: 1199px) {
      //   // margin-left: 4rem;
      // }

      h4 {
        font-weight: 400;
        text-transform: capitalize;
        font-size: 2rem;
        margin-bottom: 2.4rem;

        @media (max-width: 1679px) {
          font-size: 1.6rem;
        }

        @media (max-width: 1366px) {
          margin-bottom: 2rem;
        }
      }

      h1,
      h4 {
        font-family: $headingfont;
      }

      h1 {
        font-size: 6.4rem;
        line-height: 1.6;
        margin-bottom: 2.7rem;
        font-weight: 700;

        @media (max-width: 1679px) {
          font-size: 5rem;
        }

        @media (max-width: 1439px) {
          font-size: 3rem;
          margin-bottom: 2rem;
        }

        @media (max-width: 1199px) {
          font-size: 2.4rem;
        }

        @media (max-width: 991px) {
          font-size: 2rem;
        }
      }

      p {
        font-size: 2.2rem;
        font-family: $basefont;
        font-weight: 300;
        max-width: 50%;
        line-height: normal;

        @media (max-width: 1679px) {
          font-size: 2rem;
        }

        @media (max-width: 1366px) {
          max-width: 70%;
          font-size: 1.6rem;
        }
      }

      button {
        margin-top: 6rem;
        margin-bottom: 10rem;

        @media (max-width: 1679px) {
          margin-top: 2rem;
          margin-bottom: 7rem;
        }
      }
    }
  }

  .slick-slide {
    min-height: 35rem;

    img {
      height: 100vh;
      min-height: 30rem;
      object-fit: cover;
      object-position: center;
      width: 100vw;
      background-color: rgba(0, 0, 0, 0.7);
      filter: brightness(40%);
      // @media (max-width: 991px) {
      //   height:20rem;
      // }
    }
  }
}

.who_sec {
  // background-image: url(../../../assets/images/who_We_Are.png);
  // background-size: cover;
  // background-position: center;
  background-color: hsla(240, 7%, 54%, 0.3);
  text-align: center;

  h2 {
    font-size: 4.4rem;
    font-weight: 700;
    font-family: $headingfont;
    margin-top: 8.2rem;
    margin-bottom: 2.9rem;
    // color: $white;
    text-transform: capitalize;

    @media (max-width: 991px) {
      font-size: 3.2rem;
      margin-top: 5rem;
    }

    @media (max-width: 575px) {
      font-size: 2.4rem;
      margin-top: 3rem;
    }
  }

  p {
    font-weight: 400;
    // color: $white;
    font-size: 2.2rem;
    letter-spacing: 0.56;
    line-height: normal;

    @media (max-width: 991px) {
      font-size: 1.8rem;
    }

    @media (max-width: 575px) {
      font-size: 1.4rem;
    }
  }

  button {
    max-width: 20rem;
    width: 100%;
    border-radius: unset !important;
    margin-bottom: 4.2rem;
    font-size: 1.6rem;
    margin: 0 auto;
  }
}

.services_sec {
  margin-top: 9rem 0 2rem;

  h2 {
    font-size: 4.4rem;
    font-weight: 700;
    font-family: $headingfont;
    margin-top: 10rem;
    margin-bottom: 3rem;
    color: $black;
    text-transform: uppercase;
    font-weight: 700;

    @media (max-width: 991px) {
      font-size: 3.2rem;
      margin-top: 5rem;
    }

    @media (max-width: 575px) {
      font-size: 2.6rem;
      margin-top: 3rem;
    }
  }

  &_inner {
    background-image: url(../../../assets/images/services_bg.svg);
    background-size: cover;
    // background-position: bottom;
    padding: 6.3rem 0 22rem;
    position: relative;

    @media (max-width: 1679px) {
      padding: 6rem 0 20rem;
    }

    @media (max-width: 1439px) {
      padding: 5rem 0 15rem;
    }

    @media (max-width: 1199px) {
      padding: 4rem 0 10rem;
    }

    @media (max-width: 575px) {
      padding: 4rem 0 5rem;
    }

    .row>div {
      padding: 7.4rem 1rem;

      @media (max-width: 991px) {
        padding: 4.5rem 1rem;
      }

      @media (max-width: 575px) {
        padding: 1.5rem 1rem;
      }
    }

    &::before {
      content: "";
      position: absolute;
      background-image: url(../../../assets/images/dots.png);
      background-size: cover;
      background-position: center;
      max-width: 33rem;
      width: 100%;
      height: 40rem;
      top: 48%;
      left: 20%;

      @media (max-width: 991px) {
        display: none;
      }
    }

    &::after {
      content: "";
      position: absolute;
      background-image: url(../../../assets/images/lines.png);
      background-size: cover;
      background-position: center;
      max-width: 45.7rem;
      width: 100%;
      top: 48%;
      right: 20%;

      @media (max-width: 991px) {
        display: none;
      }
    }

    &_box {
      position: relative;
      max-width: 44.8rem;
      width: 100%;
      border-radius: 1rem;
      // margin-bottom: 13.6rem;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-inline: auto;

      &_img {
        position: relative;
        z-index: 1;

        img {
          height: 24rem;
          border-radius: 1rem 1rem 0 0;
          width: 100%;
          object-fit: cover;
        }

        &::after {
          content: "";
          width: 100%;
          height: 100%;
          background-color: rgba($black, 0.7);
          position: absolute;
          top: 0;
          border-radius: 1rem 1rem 0 0;
          left: 0;
          z-index: 0;
        }
      }

      h4 {
        position: absolute;
        color: $white;
        padding: 3rem 2rem;
        font-size: 3.4rem;
        z-index: 1;

        @media (max-width: 1679px) {
          font-size: 3rem;
          padding: 2rem;
        }

        @media (max-width: 991px) {
          font-size: 2.4rem;
        }

        @media (max-width: 575px) {
          font-size: 2rem;
        }
      }

      &_info {
        height: 100%;
        background-color: $white;
        padding: 2.5rem;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        display: flex;
        flex-direction: column;
      }

      p {
        font-size: 1.8rem;
        margin-bottom: 5.7rem;

        @media (max-width: 575px) {
          font-size: 1.4rem;
        }
      }

      button {
        margin-top: auto;
      }
    }
  }
}

.why_us {
  position: relative;
  padding: 10rem 0;
  background-color: hsla(240, 7%, 54%, 0.3);

  @media (max-width: 991px) {
    padding: 7rem 0;
  }

  @media (max-width: 575px) {
    padding: 5rem 0;
  }

  &::before {
    content: "";
    position: absolute;
    background-image: url(../../../assets/images/dots.png);
    background-size: cover;
    background-position: center;
    max-width: 33rem;
    width: 100%;
    height: 40rem;
    top: 48%;
    left: 20%;

    @media (max-width: 991px) {
      display: none;
    }
  }

  h2 {
    font-family: $headingfont;
    font-size: 4.4rem;
    text-transform: uppercase;
    margin-bottom: 14.4rem;
    font-weight: 700;

    @media (max-width: 991px) {
      font-size: 3.2rem;
      margin-bottom: 6rem;
    }

    @media (max-width: 575px) {
      font-size: 2.6rem;
      margin-bottom: 4rem;
    }
  }

  .row {
    margin: -1.5rem;

    >div {
      padding: 1.5rem;
    }
  }

  &_inner {
    border-radius: 2.4rem;
    background-color: #787898;
    text-align: center;
    padding: 2.4rem;
    color: $white;
    // margin-bottom: 1.6rem;
    height: 100%;

    h4 {
      font-size: 3.4rem;
      font-weight: 400;

      @media (max-width: 1366px) {
        font-size: 3rem;
      }

      @media (max-width: 991px) {
        font-size: 2.6rem;
      }

      @media (max-width: 575px) {
        font-size: 2rem;
      }
    }

    p {
      font-size: 2rem;
      font-weight: 400;
      line-height: normal;

      @media (max-width: 1366px) {
        font-size: 1.8rem;
      }

      @media (max-width: 575px) {
        font-size: 1.6rem;
      }
    }

    img {
      margin: 3rem 0;
    }
  }
}

.community_sec {
  background-image: url(../../../assets/images/COP.png);
  background-size: cover;
  background-position: center;
  color: $white;
  padding: 9rem 0 25rem;
  text-align: center;

  @media (max-width: 1679px) {
    padding: 9rem 0 20rem;
  }

  @media (max-width: 1439px) {
    padding: 9rem 0 15rem;
  }

  @media (max-width: 1199px) {
    padding: 7rem 0 10rem;
  }

  @media (max-width: 575px) {
    padding: 5rem 0 5rem;
  }

  h2 {
    margin-bottom: 9rem;
    font-family: $headingfont;
    font-size: 4.4rem;

    @media (max-width: 991px) {
      font-size: 3.2rem;
      margin-bottom: 6rem;
    }

    @media (max-width: 575px) {
      font-size: 2.6rem;
      margin-bottom: 4rem;
    }
  }

  p {
    font-size: 2.2rem;
    font-weight: 400;
    left: 0.56;
    line-height: normal;

    @media (max-width: 991px) {
      font-size: 2rem;
    }

    @media (max-width: 575px) {
      font-size: 1.6rem;
    }
  }
}