@import "../../../assets/theme/_var.scss";

.big_text {
    font-size: 2.8rem;
    font-weight: 400;

    @media (max-width: 1679px) {
        font-size: 2.4rem;
    }

    @media (max-width: 1439px) {
        font-size: 2rem;
    }

    @media (max-width: 1199px) {
        font-size: 1.8rem;
    }

    @media (max-width: 767px) {
        font-size: 1.6rem;
    }

    @media (max-width: 575px) {
        font-size: 1.4rem;
    }
}