@import "../../../assets/theme/_var.scss";

.social_header {
    background-color: $baseclr;

    .social_inner {
        padding: 0.4rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 575px) {
            flex-wrap: wrap;

            >div {
                flex: 0 0 100%;
            }
        }

        .social_left {
            ul {
                @media (max-width: 399px) {
                    flex-direction: column;
                }

                li {
                    a {
                        svg {
                            margin-right: 0.6rem;
                        }
                    }
                }
            }
        }

        ul {
            margin-inline: -0.8rem;
            display: flex;
            align-items: center;

            @media (max-width: 575px) {
                justify-content: center;
            }

            li {
                padding: 0.8rem;

                a {
                    color: $white;

                    svg {
                        width: 2rem;
                        height: 2rem;

                        path {
                            fill: $white;
                        }
                    }
                }
            }
        }

    }
}