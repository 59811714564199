@import "../../../assets/theme/_var.scss";

.custom_btn {
  background-color: $secondaryclr;
  border: 2px solid $secondaryclr;
  height: 4.4rem;
  border-radius: 0.8rem;
  color: $white;
  font-size: 1.6rem;
  padding-inline: 2rem;
  font-weight: 500;
  transition: 200ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  .btn_text {
    margin-right: 1rem;
  }

  .btn_loading {
    animation: spinning 3.2s ease-in-out infinite;

    svg {
      width: 3rem;
      height: 3rem;

      path {
        fill: $white;
      }
    }
  }

  &:hover {
    color: $secondaryclr;
    background-color: transparent;

    .btn_loading {
      svg {
        path {
          fill: $secondaryclr;
        }
      }
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  @media (max-width: 991px) {
    font-size: 1.4rem;
    padding-inline: 1.4rem;
    height: 4rem;
  }
}

@keyframes spinning {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
